import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Ingest Service API`}</h1>
    <p>{`Content import means, that you transfer data from external sources into a CoreMedia system and store it as
CoreMedia content.`}</p>
    <p>{`CoreMedia Content Cloud offers several ways to import content:`}</p>
    <ul>
      <li parentName="ul">{`Ingest Service API`}
        <ul parentName="li">
          <li parentName="ul">{`A hosted service for CoreMedia on Cloud customers with a REST API`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Unified API`}
        <ul parentName="li">
          <li parentName="ul">{`The common CoreMedia Java API for access to the repository`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Importer Framework`}
        <ul parentName="li">
          <li parentName="ul">{`An XSLT based framework for content import`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Content Hub`}
        <ul parentName="li">
          <li parentName="ul">{`An API for Studio which connects third-party content sources with the CoreMedia system`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Serverimport tool`}
        <ul parentName="li">
          <li parentName="ul">{`A command line tool which imports content from a previous server export`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Ingest Service API`}</h2>
    <p>{`The Ingest Service is a hosted service for CoreMedia on Cloud (CMOC) offering a REST API. You can use this
service to import content into your CoreMedia instances, hosted by CoreMedia.`}</p>
    <h3>{`Use Cases`}</h3>
    <p>{`Use the Ingest Service for content import into your hosted CoreMedia system.`}</p>
    <h3>{`Getting Access to the Ingest Service`}</h3>
    <p><em parentName="p">{`How to do this?`}</em></p>
    <h3>{`Resources`}</h3>
    <ul>
      <li parentName="ul">{`Find the Ingest Service API documentation at `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/services/ingest-service/ingest-service-api/"
        }}>{`API Reference`}</a>{`.`}</li>
      <li parentName="ul">{`Find best practices at `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/services/ingest-service/ingest-service/"
        }}>{`Working with Ingest Service`}</a>{`.`}</li>
    </ul>
    <h2>{`Unified API`}</h2>
    <p>{`The Unified API is the common Java API used to access all server aspects of the CoreMedia system. You can write your
own Unified API clients.`}</p>
    <h3>{`Use Cases`}</h3>
    <p>{`Use the Unified API`}</p>
    <h3>{`Resources`}</h3>
    <ul>
      <li parentName="ul">{`Find the Unified API Developer manual at `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/artifacts/2201/webhelp/uapi-developer-en/content/Introduction.html"
        }}>{`Developer Manual`}</a></li>
    </ul>
    <h2>{`Importer Framework`}</h2>
    <p>{`The CoreMedia Importer is an XSLT based framework that can be used to import XML based content. To do so, you have to
transform the given XML into the CoreMedia XML format.`}</p>
    <p>{`The CoreMedia Importer is seldomly used nowadays.`}</p>
    <h3>{`Use Cases`}</h3>
    <ul>
      <li parentName="ul">{`Import XML data`}</li>
    </ul>
    <h3>{`Resources`}</h3>
    <ul>
      <li parentName="ul">{`Find the Importer Developer Manual at `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/artifacts/2201/webhelp/importer-developer-en/content/Overview.html"
        }}>{`Importer Developer Manual`}</a></li>
    </ul>
    <h2>{`Content Hub`}</h2>
    <p>{`You can use existing Content Hub adapters or write your own Java adapters for the Content Hub to connect external systems with the CoreMedia system.`}</p>
    <h3>{`Use Cases`}</h3>
    <ul>
      <li parentName="ul">{`Import content from third-party systems`}</li>
      <li parentName="ul">{`Exchange data between CoreMedia systems`}</li>
    </ul>
    <h3>{`Resources`}</h3>
    <ul>
      <li parentName="ul">{`Learn how to implement your own Content Hub adapter at `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/artifacts/2201/webhelp/studio-developer-en/content/Content_Hub.html"
        }}>{`Studio Developer Manual`}</a></li>
    </ul>
    <h2>{`Serverimport Tool`}</h2>
    <p>{`The serverimport tool can be used to import content that was exported by the serverexport tool.`}</p>
    <h3>{`Use Cases`}</h3>
    <ul>
      <li parentName="ul">{`Transfer content from one CoreMedia system into another system.`}</li>
      <li parentName="ul">{`Initially set up a system with data`}</li>
    </ul>
    <h3>{`Resources`}</h3>
    <p>{`Find the description of the serverimport tool in the `}<a parentName="p" {...{
        "href": "https://documentation.coremedia.com/cmcc-11/artifacts/2201/webhelp/contentserver-en/content/CMServerimportExport.html"
      }}>{`Content Server Manual`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      